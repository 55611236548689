body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

.logo img {
  height: 40px;
}

.hamburger {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

.header-options {
  display: flex;
  list-style: none;
  gap: 20px;
}

.header-options ul {
  display: flex;
  list-style: none;
  gap: 20px;
  padding: 0;
  margin: 0;
}

.header-contact {
  display: flex;
  align-items: center;
}

.hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 40px;
  background-color: #f8f8f8;
  flex-direction: column;
  text-align: center;
}

.tag-line {
  font-family: cursive;
  text-align: center;
}

.coming-soon-message {
  text-align: center;
  font-family: cursive;
}

.app-footer {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #f8f8f8;
  border-top: 1px solid #ddd;
}

.footer-info {
  display: flex;
  gap: 30px;
}

/* Mobile Drawer Styles */
.header-options.open {
  display: block;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 10px 20px;
  border-top: 1px solid #ddd;
}

.header-options ul {
  flex-direction: row;
  gap: 10px;
}

@media (max-width: 768px) {
  .app-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-options ul {
    flex-direction: column;
    gap: 10px;
  }

  .hamburger {
    display: block;
  }

  .header-options {
    display: none;
  }

  .hero-section {
    padding: 40px 20px;
  }

  .product-image img {
    max-height: 200px;
  }

  .footer-info {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .app-header {
    padding: 5px;
  }

  .hero-section {
    padding: 20px 10px;
  }

  .product-image img {
    max-height: 150px;
  }

  .app-footer {
    padding: 10px;
  }
}
